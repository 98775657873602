import React, { useRef, useState } from 'react';

import worldImg from 'assets/imgs/worldmap-bg.png';
import { Button, Form, Input, message } from 'antd';

import Header from 'components/Header/Header';
import { useTranslation } from 'react-i18next';
import Steps from './Steps';

import styles from '../Register/Register.module.scss';
import tStyles from './Tutorial.module.scss';

function Register() {
  const { t } = useTranslation();

  return (
    <main
      className={styles.main}
      style={{
        backgroundImage: `url(${worldImg})`,
      }}
    >
      <Header />
      <div className={tStyles['login-box']}>
        <div className={styles['title-box']}>
          <h2>{t('Deeper Network Staking')}</h2>
        </div>
        <Steps />
      </div>
    </main>
  );
}

export default Register;
