import { zh as ResZh } from './res';

const zh = {
  Res: {
    ...ResZh,
  },
  'Deeper Network Staking': 'Deeper Network 质押',
  Register: '注册',
  Email: '邮箱',
  Username: '用户名',
  'Verification Code': '验证码',
  Password: '密码',
  'New Password': '新密码',
  'Repeat Password': '重复密码',
  Send: '发送验证码',
  Login: '登录',
  'Reset Password': '重置密码',
  'Forget password': '忘记密码',
  Referer: '推荐人',

  'Please input your email!': '请输入邮箱',
  'Please input your username!': '请输入用户名',
  'Please input your verification code!': '请输入验证码',
  'register|Please input your password!':
    '请输入一个复杂的密码(至少8位，包含数字、字母)',
  'Please input your password!': '请输入密码',
  'invalid email': '邮箱地址不正确。',
  'Confirmed passwords do not match': '两次密码不一致。',

  Staking: '质押',
  'Create a staking': '创建质押',
  'Staking Information': '质押信息',
  'Device SN': '设备SN号',
  'Device Deeper Chain': '设备绑定的DeeperChain钱包',
  'Please bind the DeeperChain wallet on the device':
    '请在设备上绑定DeeperChain钱包',
  'Deeper Chain Staked': 'BSC质押的DeeperChain钱包',
  'USDC Staked': '质押的USDC数量',
  'Staking Type': '质押类型',
  'Current Credit': '当前信用分',
  'Credit Tier': '信用等级',
  'DPR Price': '当前DPR价格',
  'for reference only': '仅供参考，以实际为准',
  'Staking Record': '质押记录',
  Time: '时间',
  Credit: '信用分',
  Status: '状态',
  success: '成功',
  pending: '执行中',
  'Max Credit Tier': '已到最大信用等级',
  'USDC needed': '需要USDC',
  APY: '年化收益率',
  'DPR will be staked': 'DPR质押的数量',
  Pending: '执行中',
  'Add Stake': '开始质押',
  Detail: '详情',
  'Do you Want to delete this item?': '确定要删除该记录吗？',
  Delete: '删除',
  'User denied transaction signature.': '用户取消',
  Error: '出错了',
  'Please connect to the BSC mainnet': '请连接BSC主网',
  'Please install the MetaMask plugin or use this system in the mobile wallet App.':
    '请安装MetaMask插件或者在手机钱包App中使用本系统。',
  'Basic Information': '基本信息',
  'BSC Wallet Information': 'BSC 钱包信息',
  'Wallet Address': '钱包地址',
  'Please connect to the registered BEP20 address': '请连接注册的BSC钱包',
  'USDC Balance': 'USDC 余额',
  Approve: '授权',
  Stake: '质押',
  'Reset password': '重置密码',
  Caution: '请注意',
  'The Deeper Chain wallet cannot be staked with USDC, please go to the deivce backend to stake.':
    '该DeeperChain钱包无法用USDC质押，请到设备后台质押。',
  empty: '未入网',
  'Insufficient balance': '余额不足',
  'DPR daily reward': '每日收益',
  'DPR Swaped': '兑换的DPR数量',

  Wallet: '钱包',
  Search: '搜索',
  'Watch a Wallet': '导入观察钱包',
  Refresh: '刷新信息',
  'Deeper Chain Address': 'Deeper Chain 钱包地址',
  Submit: '提交',
  Cancel: '取消',
  CAMPAIGN_ID_NULL: '未入网',
  CAMPAIGN_ID_0: '黄金创世节点',
  CAMPAIGN_ID_1: '白银创世节点',
  CAMPAIGN_ID_2: 'Basic Mining 1.0',
  CAMPAIGN_ID_3: 'DPRB Mining',
  CAMPAIGN_ID_4: 'Basic Mining 2.0',
  CAMPAIGN_ID_5: 'Easy Mining',
  'Enter Deeper Chain Address': '请输入 Deeper Chain 钱包地址',

  tutorial: {
    Step1: '第一步',
    Step1Content: '在设备后台创建DeeperChain钱包',
    Step2: '第二步',
    Step3: '第三步',
    Step4: '第四步',
    Step4Content: '打开BSC质押网站，注册用户',
    Step5: '第五步',
    Step5Content: '登录BSC质押网站，选择质押等级并质押',
    Step6: '第六步',
    Step6Content: '登录BSC质押网站，刷新页面检查升级情况',
  },
};

export default zh;
