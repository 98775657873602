import React, { useState } from 'react';
import { Button, Steps, Modal } from 'antd';

import { useTranslation } from 'react-i18next';
import styles from './Tutorial.module.scss';
import walletPng from 'assets/imgs/wallet.png';
import walletCNPng from 'assets/imgs/wallet_cn.png';

const { Step } = Steps;

function Staking() {
  const [current, setCurrent] = useState(0);
  const { t, i18n } = useTranslation();
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);

  const onChange = (value) => {
    console.log('onChange:', current);
    setCurrent(value);
  };

  const CreateWallet = (
    <div>
      <Modal
        width='800px'
        title=''
        footer={null}
        onCancel={() => {
          setCreateModalOpen(false);
        }}
        visible={isCreateModalOpen}
      >
        <p>
          <img
            style={{ width: '100%' }}
            src={i18n.language === 'en' ? walletPng : walletCNPng}
            alt=''
          />
        </p>
      </Modal>
      <Button
        onClick={() => {
          setCreateModalOpen(true);
        }}
      >
        {t('tutorial.Step1Content')}
      </Button>
    </div>
  );

  const MetaMaskInstall =
    i18n.language === 'en' ? (
      <div>
        Install
        <a
          style={{
            margin: '0 10px',
            fontWeight: 'bold',
          }}
          href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'
          target='_blank'
          rel='noreferrer'
        >
          MetaMask extension
        </a>
        in Chrome browser and{' '}
        <a
          style={{
            margin: '0 10px',
            fontWeight: 'bold',
          }}
          href='https://docs.bnbchain.org/docs/wallet/metamask'
          target='_blank'
          rel='noreferrer'
        >
          create BSC wallet
        </a>
      </div>
    ) : (
      <div>
        在Chrome浏览器安装MetaMask插件
        <a
          style={{
            margin: '0 10px',
            fontWeight: 'bold',
          }}
          href='https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn'
          target='_blank'
          rel='noreferrer'
        >
          MetaMask插件
        </a>
        并
        <a
          style={{
            margin: '0 10px',
            fontWeight: 'bold',
          }}
          href='https://docs.bnbchain.org/docs/wallet/metamask'
          target='_blank'
          rel='noreferrer'
        >
          创建BSC钱包
        </a>
      </div>
    );

  const BuyDPR =
    i18n.language === 'en' ? (
      <div>
        <div>
          Buy DPR on
          <a
            style={{
              margin: '0 10px',
              fontWeight: 'bold',
            }}
            href='https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0xa0a2ee912caf7921eaabc866c6ef6fec8f7e90a4'
            target='_blank'
            rel='noreferrer'
          >
            PancakeSwap
          </a>
          PancakeSwap on the BSC chain
        </div>
        <div>or</div>
        <div>
          Buy DPR at
          <a
            style={{
              margin: '0 10px',
              fontWeight: 'bold',
            }}
            href='https://www.gate.io/zh/trade/DPR_USDT'
            target='_blank'
            rel='noreferrer'
          >
            Gate.io
          </a>
          and withdraw to BSC wallet
        </div>
      </div>
    ) : (
      <div>
        <div>
          在 BSC 链上
          <a
            style={{
              margin: '0 10px',
              fontWeight: 'bold',
            }}
            href='https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0xa0a2ee912caf7921eaabc866c6ef6fec8f7e90a4'
            target='_blank'
            rel='noreferrer'
          >
            PancakeSwap
          </a>
          购买DPR
        </div>
        <div>或者</div>
        <div>
          在
          <a
            style={{
              margin: '0 10px',
              fontWeight: 'bold',
            }}
            href='https://www.gate.io/zh/trade/DPR_USDT'
            target='_blank'
            rel='noreferrer'
          >
            Gate.io
          </a>
          购买DPR，并提币到BSC钱包
        </div>
      </div>
    );

  const RegisterBSCStaking = (
    <div>
      <a
        href='https://device-bsc.deeper.network/register'
        target='_blank'
        rel='noreferrer'
      >
        <Button>{t('tutorial.Step4Content')}</Button>
      </a>
    </div>
  );

  const ChooseStakingLevel = (
    <div>
      <a
        href='https://device-bsc.deeper.network/login'
        target='_blank'
        rel='noreferrer'
      >
        <Button>{t('tutorial.Step5Content')}</Button>
      </a>
    </div>
  );

  const CheckStaking = (
    <div>
      <a
        href='https://device-bsc.deeper.network/login'
        target='_blank'
        rel='noreferrer'
      >
        <Button>{t('tutorial.Step6Content')}</Button>
      </a>
    </div>
  );

  return (
    <div className={styles.containerWrap}>
      <div className={styles.container}>
        <Steps current={current} onChange={onChange} direction='vertical'>
          <Step title={t('tutorial.Step1')} description={CreateWallet} />
          <Step title={t('tutorial.Step2')} description={MetaMaskInstall} />
          <Step title={t('tutorial.Step3')} description={BuyDPR} />
          <Step title={t('tutorial.Step4')} description={RegisterBSCStaking} />
          <Step title={t('tutorial.Step5')} description={ChooseStakingLevel} />
          <Step title={t('tutorial.Step6')} description={CheckStaking} />
        </Steps>
      </div>
    </div>
  );
}

export default Staking;
