export const en = {
  InvalidEmail: 'Invalid email',
  AlreadySent: 'A verification code has been sent, please try again later.',
  InvalidCode: 'Invalid code',
  EmailRegistered: 'The email has been registered.',
  LoginError: 'Username or password entered incorrectly.',
  InvalidBody: 'Please check the information again.',
  SNError: 'The device SN number is incorrect or already occupied.',
  BEP20Error: 'BEP20 address is incorrect or already occupied.',
  NoDeeperChain: 'Please bind the DeeperChain wallet on the device.',
  InvalidReferer: 'Invalid Referer',

  SendSuccess: 'Verification code sent successfully',
  RegisterSuccess: 'registration success',
  Welcome: 'Welcome, ',
  PasswordResetSuccess: 'Password reset successfully.',
  CreateSuccess: 'Created successfully.',
  DeleteSuccess: 'Delete successfully.',
};

export const zh = {
  InvalidEmail: '邮箱不正确',
  AlreadySent: '验证码已发送，请稍后再试。',
  InvalidCode: '验证码错误',
  EmailRegistered: '邮箱已经被注册。',
  LoginError: '用户名或者密码输入错误。',
  InvalidBody: '请再次检查并完善信息。',
  SNError: '设备SN号不正确或已被占用。',
  BEP20Error: 'BEP20 地址不正确或已被占用。',
  NoDeeperChain: '请在设备上绑定DeeperChain钱包',
  InvalidReferer: '推荐人填写错误。',

  SendSuccess: '验证码发送成功。',
  RegisterSuccess: '注册成功!',
  Welcome: '欢迎您，',
  PasswordResetSuccess: '密码重置成功！',
  CreateSuccess: '创建成功！',
  DeleteSuccess: '删除成功!',
};
