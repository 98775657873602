import { Button, Modal, Form, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useRequest } from 'ahooks';
import WalletApi from 'apis/wallet';
import isDeeperChainAddress from 'utils/isDeeperChainAddress';
import styles from './CreateModal.module.scss';

function CreateModal({ refresh }) {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const { run: runCreate, loading: runCreating } = useRequest(
    WalletApi.create,
    {
      manual: true,
      onSuccess(data) {
        message.success(t('Res.CreateSuccess'));
        form.resetFields();
        setIsModalVisible(false);
        refresh();
      },
    },
  );

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    console.log(form);
    const formData = form.getFieldsValue();
    if (formData.deeperChain) {
      if (!isDeeperChainAddress(formData.deeperChain)) {
        return message.error(t('Please input Deeper Chain address!'));
      }
    }
    runCreate(formData);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinishFailed = () => {};
  return (
    <>
      <Button size='large' type='primary' onClick={showModal}>
        {t('Watch a Wallet')}
      </Button>
      <Modal
        width={800}
        closable={false}
        footer={null}
        title={t('Watch a Wallet')}
        visible={isModalVisible}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          name='create'
          onFinish={handleOk}
          onFinishFailed={onFinishFailed}
        >
          <Form.Item
            name='deeperChain'
            label={t('Deeper Chain Address')}
            rules={[
              {
                required: true,
                message: t('Please input Deeper Chain address!'),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <div className={styles['btn-box']}>
            <Button onClick={handleCancel}>{t('Cancel')}</Button>
            <Button type='primary' htmlType='submit'>
              {t('Submit')}
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
}

export default CreateModal;
