import AdminApi from 'apis/admin';
import { useRequest } from 'ahooks';
import { Table, Button, Modal } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useState } from 'react';
import Search from 'components/Search/Search';

function Staking() {
  const [openMap, setOpenMap] = useState({});
  const {
    run: getList,
    data: userList,
    loading: getListLoading,
  } = useRequest(AdminApi.getStakingList, {
    onSuccess(data) {
      console.log(data);
    },
  });
  const { run: runDeleteStaking, loading: deleteStakingLoading } = useRequest(
    AdminApi.deleteStaking,
    {
      manual: true,
      onSuccess(data) {
        console.log(data);
        getList();
      },
    },
  );

  const { run: runCheckSNOpen, data: checkSNOpenData } = useRequest(
    AdminApi.checkSNOpen,
    {
      manual: true,
      onSuccess(data, params) {
        openMap[params[0]] = data;
        setOpenMap(openMap);
      },
    },
  );
  const deleteUser = (record) => {
    Modal.confirm({
      title: `确认要删除： ${record.bep20} 记录吗？`,
      onOk() {
        runDeleteStaking(record._id);
      },
    });
  };
  const checkSNOpen = (sn) => {
    runCheckSNOpen(sn);
  };
  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <Search onSearch={(val) => getList(val)} />
      </div>
      <Table
        rowKey='_id'
        dataSource={userList}
        loading={getListLoading}
        columns={[
          {
            title: 'user',
            dataIndex: 'user',
            render(user) {
              return user ? user.email : '';
            },
          },
          {
            title: 'sn',
            dataIndex: 'sn',
            render(sn) {
              return (
                <div>
                  {sn}
                  <b style={{ margin: '0 10px' }}>
                    {openMap[sn] !== undefined && `${openMap[sn]}`}
                  </b>
                  <Button
                    onClick={() => checkSNOpen(sn)}
                    style={{ marginLeft: 10 }}
                    size='small'
                    icon={<SearchOutlined />}
                  />
                </div>
              );
            },
          },
          {
            title: 'bep20',
            dataIndex: 'bep20',
          },
          {
            title: 'deeperChain',
            dataIndex: 'deeperChain',
            render(_, record) {
              return (
                <div>
                  <div>{record.deeperChain}</div>
                  {record.stakingDeeperChain !== record.deeperChain && (
                    <div style={{ color: 'orangered' }}>
                      {record.stakingDeeperChain}
                    </div>
                  )}
                </div>
              );
            },
          },
          {
            title: 'stakingAmount',
            dataIndex: 'stakingAmount',
            render(val) {
              return val ? (val / 1e18).toFixed(5) / 1 : '-';
            },
          },
          {
            title: 'credit',
            dataIndex: 'credit',
          },
          {
            title: 'creditData',
            dataIndex: 'creditData',
            render(val) {
              return val ? (
                <div>
                  <div>
                    campaignId:
                    {val.campaignId}
                  </div>
                </div>
              ) : (
                ''
              );
            },
          },
          {
            title: 'action',
            render(_, record) {
              return (
                <div>
                  <Button onClick={() => deleteUser(record)} type='danger'>
                    Delete
                  </Button>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
}

export default Staking;
