import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import StakingManager from 'containers/StakingManager/StakingManager';
import StakingDetail from 'containers/StakingDetail/StakingDetail';
import StakingContract from 'containers/StakingContract/StakingContract';
import AdminLogin from 'containers/Admin/Login/Login';
import Admin from 'containers/Admin/Admin';
import AdminUser from 'containers/Admin/User/User';
import AdminStaking from 'containers/Admin/Staking/Staking';
import AdminStakingRecord from 'containers/Admin/StakingRecord/StakingRecord';
import AdminReferer from 'containers/Admin/Referer/Referer';
import AdminSendDPRRecord from 'containers/Admin/SendDPRRecord/SendDPRRecord';
import Wallet from 'containers/Wallet/Wallet';
import ResetPassword from './containers/ResetPassword/ResetPassword';
import Register from './containers/Register/Register';
import Login from './containers/Login/Login';
import User from './containers/User/User';
import Calculator from './containers/Calcutalor/Calculator';
import Tutorial from './containers/Tutorial/Tutorial';
import StakingData from 'containers/StakingData/StakingData';

export default function Router() {
  return (
    <Routes>
      <Route path='/' element={<Navigate to='/stakingData' replace={true} />} />
      {/* <Route path='/register' element={<Register />} /> */}
      <Route path='/tutorial' element={<Tutorial />} />
      {/* <Route path='/reset' element={<ResetPassword />} /> */}
      {/* <Route path='/calculator' element={<Calculator />} /> */}
      <Route path='/stakingData' element={<StakingData />} />
      <Route path='user' element={<User />}>
        {/* <Route path='staking' element={<StakingManager />} />
        <Route path='stakingDetail' element={<StakingDetail />} />
        <Route path='stakingContract' element={<StakingContract />} /> */}
        <Route path='wallet' element={<Wallet />} />
      </Route>
      <Route path='/admin/login' element={<AdminLogin />} />
      <Route path='admin' element={<Admin />}>
        <Route path='user' element={<AdminUser />} />
        <Route path='staking' element={<AdminStaking />} />
        <Route path='stakingRecord' element={<AdminStakingRecord />} />
        <Route path='referer' element={<AdminReferer />} />
        <Route path='sendDPRRecord' element={<AdminSendDPRRecord />} />
      </Route>
    </Routes>
  );
}
