import AdminApi from 'apis/admin';
import { useRequest } from 'ahooks';
import { Table, Button, Modal, Tag } from 'antd';
import Search from 'components/Search/Search';

function StakingRecord() {
  const {
    run: getList,
    data: list,
    loading: getListLoading,
  } = useRequest(AdminApi.getStakingRecord, {
    onSuccess(data) {
      console.log(data);
    },
  });
  const { run: runDeleteStaking, loading: deleteStakingLoading } = useRequest(
    AdminApi.deleteStakingRecord,
    {
      manual: true,
      onSuccess(data) {
        console.log(data);
        getList();
      },
    },
  );
  const deleteUser = (record) => {
    Modal.confirm({
      title: `确认要删除： ${record.bep20} 记录吗？`,
      onOk() {
        runDeleteStaking(record._id);
      },
    });
  };
  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <Search onSearch={(val) => getList(val)} />
      </div>
      <Table
        rowKey='_id'
        dataSource={list}
        loading={getListLoading}
        columns={[
          {
            title: 'bep20',
            dataIndex: 'bep20',
          },
          {
            title: 'deeperChain',
            dataIndex: 'deeperChain',
          },
          {
            title: 'usdtAmount',
            dataIndex: 'usdtAmount',
            render(val) {
              return val ? (val / 1e18).toFixed(5) / 1 : '-';
            },
          },
          {
            title: 'credit',
            dataIndex: 'credit',
            render(_, record) {
              return `${record.fromCredit} -> ${record.toCredit}`;
            },
          },
          {
            title: 'creditData',
            dataIndex: 'creditData',
            render(val) {
              return val ? (
                <div>
                  <div>
                    campaignId:
                    {val.campaignId || val.campaign_id}
                  </div>
                </div>
              ) : (
                ''
              );
            },
          },
          {
            title: 'setHash',
            dataIndex: 'setHash',
            render(val, record) {
              return (
                <div style={{ wordBreak: 'break-all', width: 300 }}>{val}</div>
              );
            },
          },
          {
            title: 'status',
            dataIndex: 'status',
            render(_, record) {
              if (record.isInit) {
                return <Tag color='blue'>Init</Tag>;
              }
              if (record.checkSet) {
                return <Tag color='green'>Checked</Tag>;
              }
              if (record.haveSent) {
                return <Tag color='volcano'>Sent</Tag>;
              }
              return <Tag color='#108ee9'>Pending</Tag>;
            },
          },
          {
            title: 'action',
            render(_, record) {
              return (
                <div>
                  <Button onClick={() => deleteUser(record)} type='danger'>
                    Delete
                  </Button>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
}

export default StakingRecord;
