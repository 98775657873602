import ofetch from './fetch';

const fetch = (config) => {
  config.isAdmin = true;
  return ofetch(config);
};

function login(data) {
  return fetch({
    url: '/admin/login',
    method: 'POST',
    data,
  }).then((res) => {
    if (res) {
      window.localStorage.setItem('adminToken', res.token);
      window.localStorage.setItem(
        'adminTokenData',
        JSON.stringify(res.tokenData),
      );
    }
    return res;
  });
}

function getUserList(search) {
  return fetch({
    url: '/admin/user',
    method: 'GET',
    params: {
      search,
    },
  });
}

function deleteUser(id) {
  return fetch({
    url: '/admin/user',
    method: 'DELETE',
    params: {
      id,
    },
  });
}

function loginUser(id) {
  return fetch({
    url: '/admin/userLogin',
    method: 'POST',
    params: {
      id,
    },
  });
}

function getStakingList(search) {
  return fetch({
    url: '/admin/staking',
    method: 'GET',
    params: {
      search,
    },
  });
}

function deleteStaking(id) {
  return fetch({
    url: '/admin/staking',
    method: 'DELETE',
    params: {
      id,
    },
  });
}

function getStakingRecord(search) {
  return fetch({
    url: '/admin/stakingRecord',
    method: 'GET',
    params: {
      search,
    },
  });
}

function deleteStakingRecord(id) {
  return fetch({
    url: '/admin/stakingRecord',
    method: 'DELETE',
    params: {
      id,
    },
  });
}

function checkSNOpen(sn) {
  return fetch({
    url: '/admin/checkSNOpen',
    method: 'GET',
    params: {
      sn,
    },
  });
}

function getReferer(search) {
  return fetch({
    url: '/admin/referer',
    method: 'GET',
    params: {
      search,
    },
  });
}

function getSendDPRRecord(search) {
  return fetch({
    url: '/admin/sendDPRRecord',
    method: 'GET',
    params: {
      search,
    },
  });
}

function deleteSendDPRRecord(id) {
  return fetch({
    url: '/admin/sendDPRRecord',
    method: 'DELETE',
    params: {
      id,
    },
  });
}

export default {
  login,
  getUserList,
  loginUser,
  deleteUser,
  getStakingList,
  deleteStaking,
  getStakingRecord,
  deleteStakingRecord,
  checkSNOpen,
  getReferer,
  getSendDPRRecord,
  deleteSendDPRRecord,
};
