import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import WalletApi from 'apis/wallet';
import { useRequest } from 'ahooks';

import CreateModal from './CreateModal';
import styles from './Wallet.module.scss';
import WalletCard from './WalletCard';

function Wallet() {
  const { t } = useTranslation();
  const { run: runGetList, data: list = [] } = useRequest(WalletApi.getList);

  return (
    <div className={styles.container}>
      <div className={styles['tool-box']}>
        <CreateModal refresh={runGetList} />
      </div>
      <div className={styles['staking-box']}>
        {list.map((it) => (
          <WalletCard refresh={runGetList} key={it._id} data={it} />
        ))}
      </div>
    </div>
  );
}

export default Wallet;
