import AdminApi from 'apis/admin';
import { useRequest } from 'ahooks';
import { Table, Button, Modal } from 'antd';
import Search from 'components/Search/Search';
import moment from 'moment';

function sendDPRList() {
  const {
    run: getList,
    data: userList,
    loading: getListLoading,
  } = useRequest(AdminApi.getSendDPRRecord, {
    onSuccess(data) {
      console.log(data);
    },
  });
  const { run: runDelete, loading: deleteLoading } = useRequest(
    AdminApi.deleteSendDPRRecord,
    {
      manual: true,
      onSuccess(data) {
        console.log(data);
        getList();
      },
    },
  );
  const deleteItem = (record) => {
    Modal.confirm({
      title: `确认要删除： ${record.deeperChain} 这条记录吗？`,
      onOk() {
        runDelete(record._id);
      },
    });
  };
  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <Search onSearch={(val) => getList(val)} />
      </div>
      <Table
        rowKey='_id'
        dataSource={userList}
        loading={getListLoading}
        columns={[
          {
            title: 'time',
            dataIndex: 'createdAt',
            render(time) {
              return moment(time).format();
            },
          },
          {
            title: 'bep20',
            dataIndex: 'bep20',
          },
          {
            title: 'sn',
            dataIndex: 'sn',
          },
          {
            title: 'deeperChain',
            dataIndex: 'deeperChain',
          },
          {
            title: 'action',
            render(_, record) {
              return (
                <div>
                  <Button onClick={() => deleteItem(record)} type='danger'>
                    Delete
                  </Button>
                </div>
              );
            },
          },
        ]}
      />
    </div>
  );
}

export default sendDPRList;
