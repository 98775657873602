import { useTranslation } from 'react-i18next';

export default function useCreditType() {
  const { t } = useTranslation();
  return {
    getType(val) {
      if (val === null || val === undefined) {
        return t('CAMPAIGN_ID_NULL');
      }
      return t(`CAMPAIGN_ID_${val}`);
    },
  };
}
