import fetch from './fetch';

function getList() {
  return fetch({
    url: '/wallet/list',
    method: 'GET',
  });
}

function create(data) {
  return fetch({
    url: '/wallet/wallet',
    method: 'POST',
    data,
  });
}

function deleteWallet(id) {
  return fetch({
    url: '/wallet/wallet',
    method: 'DELETE',
    data: {
      id,
    },
  });
}

function getDetail(id) {
  return fetch({
    url: '/wallet/detail',
    method: 'GET',
    params: {
      id,
    },
  });
}

function getDetailByDeeperChain(deeperChain) {
  return fetch({
    url: '/wallet/deeperChain/detail',
    method: 'GET',
    params: {
      deeperChain,
    },
  });
}

export default {
  getList,
  create,
  deleteWallet,
  getDetail,
  getDetailByDeeperChain,
};
