import AdminApi from 'apis/admin';
import { useRequest } from 'ahooks';
import { Table, Button, Modal } from 'antd';
import moment from 'moment';
import Search from 'components/Search/Search';

function Referer() {
  const {
    run: getList,
    data: list = [],
    loading: getListLoading,
  } = useRequest(AdminApi.getReferer, {
    onSuccess(data) {
      console.log(data);
    },
  });

  return (
    <div>
      <div style={{ marginBottom: 30 }}>
        <Search onSearch={(val) => getList(val)} />
      </div>
      <Table
        rowKey='_id'
        dataSource={list}
        loading={getListLoading}
        columns={[
          {
            title: 'time',
            dataIndex: 'createdAt',
            render(time) {
              return moment(time).format();
            },
          },
          {
            title: 'userId',
            dataIndex: 'userId',
          },
          {
            title: 'referer',
            dataIndex: 'referer',
          },
          {
            title: 'deeperChain',
            dataIndex: 'deeperChain',
          },
          {
            title: 'setHash',
            dataIndex: 'setHash',
          },
        ]}
      />
    </div>
  );
}

export default Referer;
