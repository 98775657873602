import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import WalletApi from 'apis/wallet';
import { useRequest } from 'ahooks';
import Header from 'components/Header/Header';
import worldImg from 'assets/imgs/worldmap-bg.png';

import styles from './StakingData.module.scss';
import WalletCard from '../Wallet/WalletCard';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { Input, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

function Wallet() {
  const [searchDeeperChain, setSearchDeeperChain] = useState('');
  const { t } = useTranslation();
  const [params] = useSearchParams();
  const navitage = useNavigate();
  const deeperChain = params.get('deeperChain');

  const {
    run: runGetDetail,
    data,
    loading,
  } = useRequest(WalletApi.getDetailByDeeperChain, {
    manual: true,
    onError(e) {
      console.log(e);
      message.error(t('Enter Deeper Chain Address'));
    },
  });

  const onSearch = () => {
    console.log(searchDeeperChain);
    navitage(`/stakingData?deeperChain=${searchDeeperChain}`);
  };

  useEffect(() => {
    if (deeperChain) {
      runGetDetail(deeperChain);
    }
  }, [deeperChain]);

  return (
    <div
      className={styles.container}
      style={{
        backgroundImage: `url(${worldImg})`,
      }}
    >
      <Header></Header>
      <div className={styles['search-box']}>
        <Input.Search
          placeholder={t('Enter Deeper Chain Address')}
          allowClear
          enterButton={t('Search')}
          size='large'
          onSearch={onSearch}
          onChange={(e) => setSearchDeeperChain(e.target.value)}
        ></Input.Search>
      </div>
      <div className={styles['staking-box']}>
        <div className={styles['card-box']}>
          {loading && (
            <div className={styles['loading-box']}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
              />
            </div>
          )}
          {deeperChain && (
            <WalletCard
              single
              data={{
                ...data,
                deeperChain,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Wallet;
