import { Button, Modal, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRequest } from 'ahooks';
import WalletApi from 'apis/wallet';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useCreditType from 'hooks/useCreditType';
import { useEffect } from 'react';
import styles from './Wallet.module.scss';

function getNum(val) {
  if (!val) {
    return 0;
  }
  return (val / 1e18).toFixed(5) / 1;
}

function StakingCard({ data, refresh, single }) {
  const { t } = useTranslation();
  const { getType } = useCreditType();

  const { run: runDelete, loading: deleteLoading } = useRequest(
    WalletApi.deleteWallet,
    {
      manual: true,
      onSuccess(data) {
        console.log(data);
        message.success(t('Res.DeleteSuccess'));
        refresh();
      },
    },
  );

  const {
    run: runGetDetail,
    loading: getDetailLoading,
    data: detailData = data,
  } = useRequest(WalletApi.getDetail, {
    manual: true,
    onSuccess(data) {
      console.log(data);
    },
  });

  const handleDelete = () => {
    Modal.confirm({
      title: t('Do you Want to delete this item?'),
      icon: <ExclamationCircleOutlined />,
      onOk() {
        runDelete(data._id);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleRefresh = () => {
    runGetDetail(data._id);
  };

  useEffect(() => {
    if (detailData.credit === undefined && refresh) {
      runGetDetail(data._id);
    }
  }, [detailData, refresh]);

  if (data && data.bscV2) {
    data.bsc = data.bsc / 1 + data.bscV2 / 1;
  }

  return (
    <div
      className={
        single ? styles['card-container-single'] : styles['card-container']
      }
      key={detailData._id}
    >
      <div className={styles['wallet-box']}>{detailData.deeperChain}</div>
      <div className={styles['info-container']}>
        <div className={styles['text-row']}>
          <div className={styles['text-label']}>{t('Staking Type')}:</div>
          <div className={styles['text-value']}>
            {getType(
              detailData.creditData ? detailData.creditData.campaignId : null,
            )}
          </div>
        </div>
        <div className={styles['text-row']}>
          <div className={styles['text-label']}>{t('Credit')}:</div>
          <div className={styles['text-value']}>{detailData.credit || '0'}</div>
        </div>
        {getNum(detailData.genesis) > 0 && (
          <div className={styles['text-row']}>
            <div className={styles['text-label']}>
              {t('Genesis Node Staking')}:
            </div>
            <div className={styles['text-value']}>
              {getNum(detailData.genesis)} DPR
            </div>
          </div>
        )}
        {getNum(detailData.eth) > 0 && (
          <div className={styles['text-row']}>
            <div className={styles['text-label']}>
              {t('Basic Mining (ETH)')}:
            </div>
            <div className={styles['text-value']}>
              {getNum(detailData.eth)} DPR
            </div>
          </div>
        )}
        {getNum(detailData.bsc) > 0 && (
          <div className={styles['text-row']}>
            <div className={styles['text-label']}>
              {t('Basic Mining (BSC)')}:
            </div>
            <div className={styles['text-value']}>
              {getNum(detailData.bsc)} DPR
            </div>
          </div>
        )}
        {getNum(detailData.deeperChainStaking) > 0 && (
          <div className={styles['text-row']}>
            <div className={styles['text-label']}>
              {t('Deeper Chain Staking')}:
            </div>
            <div className={styles['text-value']}>
              {getNum(detailData.deeperChainStaking)} DPR
            </div>
          </div>
        )}
        {getNum(detailData.easy) > 0 && (
          <div className={styles['text-row']}>
            <div className={styles['text-label']}>{t('Easy Mining')}:</div>
            <div className={styles['text-value']}>
              {getNum(detailData.easy)} USDC ( {detailData.easyDeeperChainDPR}{' '}
              DPR )
            </div>
          </div>
        )}
        {detailData.bscStakingCode && (
          <div className={styles['text-row']}>
            <div className={styles['text-label']}>
              {t('Staking Code (BSC)')}:
            </div>
            <div className={styles['text-value']}>
              {detailData.bscStakingCode._id} ( 2000 DPR )
            </div>
          </div>
        )}
        {detailData.ethStakingCode && (
          <div className={styles['text-row']}>
            <div className={styles['text-label']}>
              {t('Staking Code (ETH)')}:
            </div>
            <div className={styles['text-value']}>
              {detailData.ethStakingCode._id} ( 2000 DPR )
            </div>
          </div>
        )}
      </div>

      {refresh && (
        <div className={styles['text-tool-box']}>
          <Button
            loading={getDetailLoading}
            onClick={() => handleRefresh()}
            style={{ marginRight: '20px' }}
            type='primary'
          >
            {t('Refresh')}
          </Button>
          <Button
            loading={deleteLoading}
            onClick={handleDelete}
            style={{ marginRight: '20px' }}
            type='danger'
          >
            {t('Delete')}
          </Button>
        </div>
      )}
    </div>
  );
}

export default StakingCard;
