import { useState, useEffect } from 'react';
import { useRequest } from 'ahooks';
import { Spin, Divider, Button, Modal, message } from 'antd';
import StakingService from 'apis/staking';
import useUrlState from '@ahooksjs/use-url-state';
import EthService from 'eth/index';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styles from './StakingContract.module.scss';

function StakingContract() {
  const navigate = useNavigate();
  const [query, setQuery] = useUrlState();
  const [deeperChainToBeStaked, setDeeperChainToBeStaked] = useState();
  const [USDAmount, setUSDAmount] = useState(query.usdt);
  const [chainId, setChainId] = useState();
  const [sameBEP20, setSameBEP20] = useState(true);
  const { t } = useTranslation();
  const [stakeDisable, setStakeDisable] = useState(false);

  if (window.ethereum) {
    window.ethereum.on('accountsChanged', (accounts) => {
      window.location.reload();
    });
  }

  const {
    run: runGetDetail,
    loading: runGetDetailing,
    data: detailData = {
      deeperChain: '-',
    },
  } = useRequest(StakingService.getStakingById, {
    defaultParams: [query.id],
    onSuccess(data) {
      if (!data.stakingDeeperChain && !data.deeperChain) {
        navigate(`/user/stakingDetail?id=${query.id}`);
        return;
      }
      setDeeperChainToBeStaked(data.stakingDeeperChain || data.deeperChain);
    },
  });

  const {
    loading: runGetApproveDataLoading,
    run: runGetApproveData,
    data: approveData,
  } = useRequest(EthService.getStakingApprove, {
    manual: true,
    onSuccess(data) {
      console.log(data);
    },
  });

  const {
    loading: runGetAddressListLoading,
    run: runGetAddressList,
    data: walletData = {},
  } = useRequest(EthService.getAddress, {
    onSuccess(data) {
      console.log(data);
      const chainId = data.chainId / 1;
      setChainId(chainId);
      runGetUsdtBalance(data.address);
      if (chainId != 56) {
        Modal.error({
          title: t('Error'),
          content: t('Please connect to the BSC mainnet'),
        });
      }
    },
    onError(e) {
      Modal.error({
        title: t('Error'),
        content: t(
          'Please install the MetaMask plugin or use this system in the mobile wallet App.',
        ),
      });
    },
  });

  const {
    run: runGetUsdtBalance,
    data: usdtBalance = 0,
    loading: runGetUsdtBalanceLoading,
  } = useRequest(EthService.getUsdBalance, {
    manual: true,
    onSuccess(data) {
      console.log(data);
    },
  });

  const {
    run: runApprove,
    data: approveRes,
    loading: runApproveLoading,
  } = useRequest(EthService.stakingApprove, {
    manual: true,
    onSuccess(data) {
      message.success('approve successfully');
      runGetApproveData(detailData.bep20);
      console.log(data);
    },
    onError(e) {
      if (e.code == '4001') {
        message.error(t('User denied transaction signature.'));
        return;
      }
      Modal.error({
        title: 'error',
        content: JSON.stringify(e),
      });
    },
  });

  const {
    run: runStake,
    data: stakeRes,
    loading: runStakeLoading,
  } = useRequest(EthService.stake, {
    manual: true,
    onSuccess(data) {
      message.success('stake successfully');
      navigate(`/user/stakingDetail?id=${query.id}`);
      console.log(data);
    },
    onError(e) {
      if (e.code == '4001') {
        message.error(t('User denied transaction signature.'));
        return;
      }
      Modal.error({
        title: 'error',
        content: JSON.stringify(e),
      });
    },
  });

  useEffect(() => {
    if (approveData === undefined && detailData.bep20 && walletData.address) {
      if (detailData.bep20.toLowerCase() == walletData.address.toLowerCase()) {
        runGetApproveData(detailData.bep20);
        return;
      }
      setSameBEP20(false);
    }
  }, [detailData, walletData, approveData]);

  useEffect(() => {
    let disable = false;
    if (!sameBEP20) {
      disable = true;
    }
    if (USDAmount > (usdtBalance / 1e18).toFixed(5) / 1) {
      disable = true;
    }
    setStakeDisable(disable);
  }, [sameBEP20, USDAmount, usdtBalance]);

  const toStake = () => {
    runStake({
      bep20: walletData.address,
      deeperChain: deeperChainToBeStaked,
      amount: USDAmount,
    });
  };
  const toApprove = () => {
    runApprove(walletData.address);
  };

  return (
    <Spin spinning={runGetDetailing}>
      <div className={styles.container}>
        <div className={styles['info-container']}>
          <h3 className={styles['info-h3']}>{t('Staking Information')}</h3>

          <Divider plain orientation='left'>
            {t('Basic Information')}
          </Divider>
          <div className={styles['text-row']}>
            <div className={styles['text-label']}>BEP20:</div>
            <div className={styles['text-value']}>{detailData.bep20}</div>
          </div>

          <div className={styles['text-row']}>
            <div className={styles['text-label']}>Deeper Chain:</div>
            <div className={styles['text-value']}>{deeperChainToBeStaked}</div>
          </div>
          <Divider plain orientation='left'>
            {t('BSC Wallet Information')}
          </Divider>
          <div className={styles['text-row']}>
            <div className={styles['text-label']}>{t('Wallet Address')}:</div>
            <div className={styles['text-value']}>
              {walletData.address}

              {!sameBEP20 && (
                <div style={{ color: 'orangered' }}>
                  {t('Please connect to the registered BEP20 address')}
                </div>
              )}
            </div>
          </div>
          <div className={styles['text-row']}>
            <div className={styles['text-label']}>{t('USDC Balance')}:</div>
            {runGetUsdtBalanceLoading ? (
              <Spin />
            ) : (
              <div className={styles['text-value']}>
                {usdtBalance ? (usdtBalance / 1e18).toFixed(5) / 1 : '0'}

                {USDAmount > (usdtBalance / 1e18).toFixed(5) / 1 && (
                  <span style={{ color: 'orangered' }}>
                    ({t('Insufficient balance')})
                  </span>
                )}
              </div>
            )}
          </div>
          <Divider />
          <div className={styles['usdt-box']}>
            <div className={styles['usdt-value']}>{USDAmount}</div>
            <div className={styles['usdt-label']}>USDC</div>
          </div>
          <div className={styles['btn-box']}>
            {approveData == '0' ? (
              <Button
                loading={runApproveLoading}
                className={styles['approve-btn']}
                disabled={stakeDisable}
                type='primary'
                onClick={toApprove}
                block
              >
                {t('Approve')}
              </Button>
            ) : (
              <Button
                loading={runStakeLoading}
                disabled={stakeDisable}
                type='primary'
                onClick={toStake}
                block
              >
                {t('Stake')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default StakingContract;
