const BigNumber = require('bignumber.js');
const Web3 = require('web3');
const StakingToken = require('./staking/index');
const USDToken = require('./usd/index');

const networkCode = process.env.NODE_ENV === 'production' ? 56 : 56;

function getWeb3() {
  if (window.ethereum) {
    return new Web3(window.ethereum);
  }
  return false;
}

function getTokenContract(Token) {
  const web3 = getWeb3();
  return new web3.eth.Contract(
    Token[networkCode].abi,
    Token[networkCode].address,
  );
}

function getTokenData(Token) {
  return Token[networkCode];
}

async function checkEnable() {
  if (!window.ethereum) {
    throw 'Ethereum Disable';
  }
  return window.ethereum;
}
export default {
  async getAddress() {
    await checkEnable();
    const addressList = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });
    return {
      chainId: window.ethereum.chainId / 1,
      address: addressList[0] ? addressList[0].toLowerCase() : '',
    };
  },
  async getStakingDataByBEP20(bep20) {
    const stakingContract = getTokenContract(StakingToken);
    const stakingDeeperChain = await stakingContract.methods
      .getUserDPRAddress(bep20)
      .call();
    const stakingAmount = await stakingContract.methods
      .getStaking(bep20)
      .call();
    const stakingDPRAmount = await stakingContract.methods
      .getUserSwapAmount(bep20)
      .call();
    return {
      stakingDeeperChain,
      stakingAmount,
      stakingDPRAmount,
    };
  },
  async getUsdBalance(bep20) {
    const usdContract = getTokenContract(USDToken);
    const balance = await usdContract.methods.balanceOf(bep20).call();
    return balance;
  },
  async getStakingApprove(bep20) {
    const usdContract = getTokenContract(USDToken);
    const approve = await usdContract.methods
      .allowance(bep20, getTokenData(StakingToken).address)
      .call();
    return approve;
  },
  async stakingApprove(bep20) {
    const usdContract = getTokenContract(USDToken);
    const approve = await usdContract.methods
      .approve(
        getTokenData(StakingToken).address,
        new BigNumber(1 * 1e8 * 1e18),
      )
      .send({
        from: bep20,
      });
    return approve;
  },
  async stake({ bep20, deeperChain, amount }) {
    console.log(amount);
    const stakingContract = getTokenContract(StakingToken);
    const beforeStakingAmount = await stakingContract.methods
      .getStaking(bep20)
      .call();
    if (beforeStakingAmount == 0) {
      await stakingContract.methods
        .stake(deeperChain, new BigNumber(amount * 1e18))
        .send({
          from: bep20,
        });
    } else {
      await stakingContract.methods
        .addAndExtendStaking(new BigNumber(amount * 1e18))
        .send({
          from: bep20,
        });
    }
  },
};
